import React, { useState } from 'react';
import { Link } from 'react-scroll';
import logo from '../images/SEAMOMENTS_FLAT.jpg';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuItemClick = () => {
    setMenuOpen(false); // Close the menu when a menu item is clicked
  };

  return (
    <div>
      <nav>
        <Link to='main' className='logo' smooth={true} duration={2000} onClick={handleMenuItemClick}>
          <img src={logo} alt='logo' className='logo-img'/>
        </Link>
        <input className='menu-btn' type='checkbox' id='menu-btn' checked={menuOpen} onChange={() => setMenuOpen(!menuOpen)} />
        <label className='menu-icon' htmlFor='menu-btn'>
          <span className='nav-icon'></span>
        </label>
        <ul className={`menu${menuOpen ? ' open' : ''}`}>
          <li><Link to='main' smooth={true} duration={1000} onClick={handleMenuItemClick}>Home</Link></li>
          <li><Link to='intro' smooth={true} duration={1000} onClick={handleMenuItemClick}>Vessel</Link></li>
          <li><Link to='destination' smooth={true} duration={1000} onClick={handleMenuItemClick}>Tours</Link></li>
          <li><Link to='contact' smooth={true} duration={1000} onClick={handleMenuItemClick}>Contact</Link></li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
