import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-scroll';

function Header() {
  return (
    <div id='main'>
      <Navbar />
      <div className='name'>
        <h1><span>SANTORINI BOAT TOURS</span></h1>
        <p className='details'>Sail the Aegean Beauty</p>
        <div className='header-btns'>
          <Link to='intro' smooth={true} duration={1000} className='explore-btn'>Explore</Link>
        </div>
      </div>
    </div>
  )
}

export default Header;

