import React, { useState } from 'react';
import Box from './Box';
import image1 from '../images/skafos.jpg';
import image2 from '../images/skafos2.jpg';
import image3 from '../images/skafos3.jpg';
import sunset from '../images/sunset.jpg';

function Destinations() {
  const [openBox, setOpenBox] = useState(null);

  const handleBoxClick = (boxTitle) => {
    if (openBox === boxTitle) {
      setOpenBox(null); // Close the currently open box
    } else {
      setOpenBox(boxTitle); // Open the clicked box
    }
  };

  return (
    <div id='destination'>
      <div className='s-heading'>
        <h1>Destinations</h1>
        <p>Here are the different destinations</p>
      </div>
      <div className='b-container'>
        <Box
          image={image1}
          alt='image1'
          title='6 Hour Tour'
          isSelected={openBox === '6 Hour Tour'}
          onClick={() => handleBoxClick('6 Hour Tour')}
          description='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.orem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.'
        />
        <Box
          image={image2}
          alt='image2'
          title='4 Hour Tour'
          isSelected={openBox === '4 Hour Tour'}
          onClick={() => handleBoxClick('4 Hour Tour')}
          description='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.'
        />
        <Box
          image={sunset}
          alt='sunset'
          title='Sunset Tour'
          isSelected={openBox === 'Sunset Tour'}
          onClick={() => handleBoxClick('Sunset Tour')}
          description='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.'
        />
        <Box
          image={image3}
          alt='image3'
          title='All Day Tour'
          isSelected={openBox === 'All Day Tour'}
          onClick={() => handleBoxClick('All Day Tour')}
          description='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis aliquam saepe eos, earum laudantium quisquam repellendus iure tenetur debitis dolor quidem incidunt, ab sapiente corrupti at! Quidem dolorem esse nulla.'
        />
        
      </div>
    </div>
  );
}

export default Destinations;
