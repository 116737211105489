import React from 'react';
import ImageSlider from './ImageSlider';
import gomenes from "../images/gomenes.jpg";
import gomenes2 from "../images/gomenes2.jpg";
import gomenes3 from "../images/gomenes3.jpg";
import pirate from "../images/background.jpg";



function Boat() {

  const slides = [
    gomenes, gomenes2, gomenes3,pirate
   ]

  
  return (
    <div id='boat'>
      <h2>Our boat</h2>
      <ImageSlider slides={slides} />
    </div>
  );
};

export default Boat;