import React from "react";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import Destinations from "./components/Destinations";
import Contact from "./components/Contact";
import Intro from "./components/Intro";
import Boat from "./components/Boat";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Sea Moments - Santorini Boat Tours</title>
        <meta name="description" content="Explore the beauty of Santorini with our unforgettable boat tours. Book now with Sea Moments for an amazing experience!" />
        <meta name="keywords" content="Santorini, boat tours, tours, sea excursions, Santorini attractions, Sea Moments" />
        <meta property="og:title" content="Sea Moments - Santorini Boat Tours" />
        <meta property="og:description" content="Explore the beauty of Santorini with our unforgettable boat tours. Book now with Sea Moments for an amazing experience!" />
        <meta property="og:image" content="url to shareable image" />
        <meta property="og:url" content="https://www.seamoments.gr" />
      </Helmet>

      <Header />
      <Intro />
      <Boat />
      <Destinations />
      <Contact />
    </div>
  );
}

export default App;
