import React, { useState } from 'react';

function Box(props) {

  
  return (
    <div
      className={` ${props.isSelected ? 'selected' : 's-box'}`}
      onClick={() => {
        props.onClick();
      }}
    >
      <div className={`${props.isSelected ? 'expanded-img' : 's-b-img'}`}>
        <img src={props.image} alt={props.alt} />
      </div>
      <div className={`${props.isSelected ? 'expanded-text' : 's-b-text'}`}>
        <h3>{props.title}</h3>
        <p className={props.isSelected ? 'dest-p-active' : 'dest-p'}>
          {props.description}
        </p>
        <h4 className='show' >
          { props.isSelected? "Show Less" : "Show More"}
        </h4>
      </div>
    </div>
  );
}

export default Box;
