import React from 'react';

function Intro() {
  return (
    <div className='intro' id='intro'>
      <h3>Welcome to our tours!</h3>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, pariatur sunt! Natus voluptatem, odit, perferendis iusto hic consequuntur nobis nostrum aut magni quas sunt porro ut ducimus expedita illum necessitatibus.Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, pariatur sunt! Natus voluptatem, odit, perferendis iusto hic consequuntur nobis nostrum aut magni quas sunt porro ut ducimus expedita illum necessitatibus.Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, pariatur sunt! Natus voluptatem, odit, perferendis iusto hic consequuntur nobis nostrum aut magni quas sunt porro ut ducimus expedita illum necessitatibus.</p>
    </div>
  )
}

export default Intro;