import React, { useState } from "react";

function ImageSlider({ slides }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slideStyles = {
    backgroundImage: `url(${slides[currentIndex]})`,
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    borderRadius: "10px",
    backgroundSize: "cover",
  };

  const goToPrev = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const renderCircles = () => {
    return slides.map((slide, slideIndex) => (
      <div
        key={slideIndex}
        onClick={() => goToSlide(slideIndex)}
        className={currentIndex === slideIndex ? "active" : ""}
      >
        <i className={currentIndex === slideIndex ? "fa-solid fa-circle" : "fa-regular fa-circle"}></i>
      </div>
    ));
  };

  return (
    <div id="slider">
      <div id="left-arrow" onClick={goToPrev}>
        <i className="fa-solid fa-arrow-left fa-2xl"></i>
      </div>
      <div id="right-arrow" onClick={goToNext}>
        <i className="fa-solid fa-arrow-right fa-2xl"></i>
      </div>
      <div style={slideStyles}></div>
      <div id="dot-container">{renderCircles()}</div>
    </div>
  );
}

export default ImageSlider;
