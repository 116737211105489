
import React, { useState,useEffect } from 'react';

function Contact() {
  const [currentYear, setCurrentYear] = useState('');

  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year.toString());
  }, []);

  return (
    <div id='contact'>
      <h3>Contact us</h3>
      <h4>Copyright © Sea Moments {currentYear}</h4>
    </div>
  )
}

export default Contact;